const Colors = (function () {
  const _blue = "#2187FB";
  const _black = "#333";
  const _white = "#F2F2F2";

  const _pureWhite = "#FFF";
  const _pureBlack = "#000";

  const _red = "#ff3b30";
  const _green = "#59a544";
  const _yellow = "#f2b432";

  const _gray_1 = "#f9f9f9";
  const _gray_2 = "#fafafa";
  const _gray_3 = "#8d8d8d";
  const _gray_4 = "#eeeeee";

  // Colors for graphs:
  const _pink = "#F8CFC7";
  const _yellow2 = "#F2B432";
  const _light_blue = "#53CDF9";
  const _blue3 = "#466EB6";
  const _green2 = "#3BB001";

  const getRandomColor = () => {
    return "#" + (((1 << 24) * Math.random()) | 0).toString(16);
  };

  const getGraphColor = (index = null) => {
    const graph_colors = [
      _light_blue,
      _pink,
      _blue,
      _yellow2,
      _blue3,
      _green2,
      _black,
    ];
    if (index === null || index >= graph_colors.length) return getRandomColor();
    return graph_colors[index];
  };

  const getMoodColor = (mood) => {
    if (mood === "happy") {
      return `${_green}88`;
    } else if (mood === "normal") {
      return `${_yellow}88`;
    } else if (mood === "sad") {
      return `${_red}88`;
    } else {
      return `${_white}88`;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return _green;
      case "inactive":
        return _gray_3;
      case "pending":
        return _yellow;
      case "banned":
        return _red;
      default:
        return _blue;
    }
  };

  return {
    _blue,
    _red,
    _green,
    _yellow,
    _pureWhite,
    _pureBlack,
    _gray_1,
    _gray_2,
    _gray_3,
    _gray_4,
    _white,
    _black,
    getGraphColor,
    getMoodColor,
    getStatusColor,
  };
})();

export default Colors;
