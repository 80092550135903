import { Role } from "utils";

import {
  HomePage,
  ProfilePage,
  PreviewPage,
  UserProfilePage,
  UsersPage,
  CaregiversPage,
  CareHomesPage,
  CareCirclesPage,
  SocialCirclesPage,
  SkillCirclesPage,
  LJPage,
  CreateLJPage,
  EditLJPage,
  StepsPage,
  EditStepPage,
  DataContentPage,
  CreateContentPage,
  EditContentPage,
  ResourcesPage,
  CreateResourcePage,
  EditResourcePage,
  DashboardThriversPage,
  DashboardCaregiverPage,
  DashboardMoodPage,
  DashboardEngagementPage,
  ThrivingAIAnalyticsPage,
  CalendarPage,
  CalendarCreatePage,
  CalendarEditPage,
  CalendarEventsPage,
  RecordsPage,
  IoTPage,
  IoTLinkPage,
  IoTEditPage,
  IoTLocationsPage,
  ReportsPage,
  VersionControlPage,
  CustomerJourneyPage,
  GuidesPage,
  CarePlanPage,
} from "./imports";

// Routes user can access based on its dashboard permissions

// Shared Routes (careHome, caregiver, admin)
const sharedRoutes = [
  { path: "/", name: "Home" },
  { path: "/home", name: "", component: HomePage },
  { path: "/profile", name: "Profile", component: ProfilePage },
  { path: "/user-profile", name: "User profile", component: UserProfilePage },
  { path: "/users", name: "Users", component: UsersPage },
  { path: "/user-preview", name: "User Preview", component: PreviewPage },
  { path: "/care-circles", name: "Care Circles", component: CareCirclesPage },
  {
    path: "/social-circles",
    name: "Social Circles",
    component: SocialCirclesPage,
  },
  // Dashboards
  {
    path: "/dashboard/thrivers",
    name: "Analytics Per Thriver",
    component: DashboardThriversPage,
  },
  {
    path: "/dashboard/mood",
    name: "Mood Analytics",
    component: DashboardMoodPage,
  },
  {
    path: "/dashboard/engagement",
    name: "Engagement Analytics",
    component: DashboardEngagementPage,
  },
  // Guides
  {
    path: "/guides",
    name: "Guides",
    component: GuidesPage,
  },
];

// Routes for careHomeAdmin and superAdmin
const sharedAdminRoutes = [
  ...sharedRoutes,
  { path: "/caregivers", name: "Caregivers", component: CaregiversPage },
  // Content space
  { path: "/resources", name: "Resources", component: ResourcesPage },
  {
    path: "/resources/create",
    name: "Create Resource",
    component: CreateResourcePage,
  },
  {
    path: "/resources/edit/:id",
    name: "Edit Resource",
    component: EditResourcePage,
  },
  // Content space
  { path: "/content", name: "Content", component: DataContentPage },
  {
    path: "/content/create",
    name: "Create Content",
    component: CreateContentPage,
  },
  {
    path: "/content/edit/:id",
    name: "Edit Content",
    component: EditContentPage,
  },
  // Dashboard
  {
    path: "/dashboard/caregivers",
    name: "Analytics Per Caregiver",
    component: DashboardCaregiverPage,
  },
  // Skill circles
  {
    path: "/skill-circles",
    name: "Skill Circles",
    component: SkillCirclesPage,
  },
];

// SuperAdmin Routes
const adminRoutes = [
  { path: "/carehomes", name: "CareHomes", component: CareHomesPage },
  {
    path: "/dashboard/thriving-ai",
    name: "Thriving.ai Analytics",
    component: ThrivingAIAnalyticsPage,
  },
  // Learning Journeys
  { path: "/learning-journeys", name: "Learning Journeys", component: LJPage },
  {
    path: "/learning-journeys/create",
    name: "Create",
    component: CreateLJPage,
  },
  {
    path: "/learning-journeys/edit/:id",
    name: "Edit",
    component: EditLJPage,
  },
  {
    path: "/learning-journeys/steps/:id",
    name: "Create Steps",
    component: StepsPage,
    exact: true,
  },
  {
    path: "/learning-journeys/step-edit/:idLJ/:idST",
    name: "Edit Step",
    component: EditStepPage,
    exact: true,
  },
  // Reports
  {
    path: "/reports",
    name: "Reports",
    component: ReportsPage,
  },
  // Version control
  {
    path: "/version-control",
    name: "Version Control",
    component: VersionControlPage,
  },
  {
    path: "/customer-journey",
    name: "Customer Journey",
    component: CustomerJourneyPage,
  },
];

// CareHome Routes
const careHomeRoutes = [
  // Calendar
  { path: "/calendar", name: "Calendar", component: CalendarPage },
  {
    path: "/calendar/events",
    name: "Events",
    component: CalendarEventsPage,
  },
  {
    path: "/calendar/create",
    name: "Create Event",
    component: CalendarCreatePage,
  },
  {
    path: "/calendar/edit",
    name: "Edit Event",
    component: CalendarEditPage,
  },
  // Records
  {
    path: "/activity-record",
    name: "Caregivers Records",
    component: RecordsPage,
  },
  // Care plan
  {
    path: "/care-plan",
    name: "Care Plan",
    component: CarePlanPage,
  },
];

export const getRoutes = ({ location, viewAs }) => {
  setLocationRoutes(location);
  if (viewAs?.identifier) return [...sharedAdminRoutes];
  if (Role.careHomeAccess()) return [...sharedAdminRoutes, ...careHomeRoutes];
  if (Role.adminAccess()) return [...sharedAdminRoutes, ...adminRoutes];
  return [...sharedRoutes];
};

const setLocationRoutes = (location) => {
  const isFromUK = location === "UK";

  if (isFromUK) {
    sharedAdminRoutes.push(
      //Iot
      { path: "/iot", name: "Smart Devices", component: IoTPage },
      { path: "/iot/link", name: "Link Device", component: IoTLinkPage },
      { path: "/iot/edit/:id", name: "Edit Device", component: IoTEditPage },
      { path: "/iot/locations", name: "Locations", component: IoTLocationsPage }
    );
  }
};
