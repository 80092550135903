import { Constants } from "utils";

class UserModel {
  constructor(user) {
    this.identifier = user?.identifier || user?.id || "";
    this.type = user?.type;
    this.dashboardType = user?.dashboardType;
  }

  // User roles
  isCareHome = () => this.type === Constants.userTypes.careHome;
  isCaregiver = () => this.type === Constants.userTypes.caregiver;
  isSuperAdmin = () => this.type === Constants.userTypes.caregiver;

  // User dashboard types
  isAdmin_super = () => this.dashboardType === Constants.dashboardRoles.admin;
  isAdmin_careProvider = () =>
    this.dashboardType === Constants.dashboardRoles.caregiver;
  isAdmin_careHome = () =>
    this.dashboardType === Constants.dashboardRoles.careHome;

  // Portal access
  hasAccessToPortal = () => this.isAdmin_super() || this.isAdmin_careHome();
}

export default UserModel;
