import { createSlice } from "@reduxjs/toolkit";
import {
  dateTypesKeys,
  getFilterTypeTitle,
  getFirstAndSecondDates,
  getLabels,
} from "./Dashboard.utils";

export const sliceName = "dashboard";

const initDateFilter = dateTypesKeys?.last31days;
const { firstDate, secondDate } = getFirstAndSecondDates({
  dateType: initDateFilter,
});
const labels = getLabels(firstDate, initDateFilter);
const filterTitle = getFilterTypeTitle(initDateFilter);

const initialState = {
  dates: {
    dateTypeSelected: initDateFilter,
    startDate: firstDate,
    endDate: secondDate,
    labels: labels,
    filterTitle: filterTitle,
  },
  engagement: {
    userType: "thriver",
  },
};

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // Dates
    store_setDateTypeSelected: (state, action) => {
      const { dateType, customDate = "" } = action.payload;

      const { firstDate, secondDate } = getFirstAndSecondDates({
        dateType,
        customDate,
      });

      state.dates.startDate = firstDate;
      state.dates.endDate = secondDate;

      state.dates.labels = getLabels(firstDate, dateType);
      state.dates.filterTitle = getFilterTypeTitle(dateType, customDate);
      state.dates.dateTypeSelected = dateType;
    },
    store_setEngagementUserType: (state, action) => {
      state.engagement.userType = action.payload;
    },
  },
});

export const store_getDashboardDateType = (store) =>
  store?.dashboard?.dates?.dateTypeSelected;

export const store_getDashboardDateLabels = (store) =>
  store?.dashboard?.dates?.labels;

export const store_getDashboardStartDate = (store) =>
  store?.dashboard?.dates?.startDate;

export const store_getDashboardEndDate = (store) =>
  store?.dashboard?.dates?.endDate;

export const store_getDashboardFilterTitle = (store) =>
  store?.dashboard?.dates?.filterTitle;

export const store_getEngagementUserType = (store) =>
  store?.dashboard?.engagement?.userType;

export const { store_setDateTypeSelected, store_setEngagementUserType } =
  slice.actions;
export default slice.reducer;
