import { store } from "store/index";
import { getLocalStore } from "./localStorage";
import { Constants } from "utils";

// To retrieve dashboard privileges
const Role = (function () {
  const getDashboardType = () =>
    store.getState().auth?.user?.dashboardType ??
    getLocalStore()?.dashboardType ??
    null;

  const getId = () =>
    store.getState().auth?.user?.identifier ??
    getLocalStore()?.identifier ??
    null;

  const adminAccess = () =>
    getDashboardType() === Constants.dashboardRoles.admin;

  const careHomeAccess = () =>
    getDashboardType() === Constants.dashboardRoles.careHome;

  const caregiverAccess = () =>
    getDashboardType() === Constants.dashboardRoles.caregiver;

  return {
    getId,
    adminAccess,
    careHomeAccess,
    caregiverAccess,
  };
})();

export default Role;
